import React from "react"

import Layout from "../layouts"
// import SEO from "../components/seo"

const ContactComplete = () => (
  <Layout>
  
    <h1>Thank you</h1>
    <p>We will get back to you soon</p>
  
  </Layout>
)

export default ContactComplete
